import base from '@/api/base';

let newPayload = null;
export let controlPayload = false;
let prevStreet = '';
let currentPage = 1;
let allResults = [];

export async function savePayload (payload) {
  newPayload = payload;
}
export async function saveResults (street, page, results) {  
  prevStreet = street;
  currentPage = page += 1;
  allResults = results;  
}
export async function getMoreDataResults () {
  
  const payload = await newPayload;
  
  if (payload) {    
    controlPayload = true;
  }  
  
  if (controlPayload) {
    
    payload.page = currentPage;

    const { data } = await base.post('person-data/search/', payload);
    
    currentPage += 1;

    try {
      if (data.result && data.result.length > 0) {
       
        return data.result;
      }
    } catch (error) {
      console.error(error);

      return [];
    }

    return [];
  }

  ;
}
