<template>
  <div class="button-area">
    <Button
      variant="secondary"
      @click="handleOnClean"
    >
      Limpar dados
    </Button>
    <v-tooltip
      v-if="tooltip"
      top
    >
      <template #activator="{ on }">
        <span v-on="disabled ? on : ''">
          <Button
            variant="primary"
            :disabled="disabled"
            @click="onConfirm"
          >
            <SpinnerIcon
              v-if="isLoading"
              class="spinner"
            />
            <span v-else>{{ buttonText }}</span>
          </Button>
        </span>
      </template>
      <span>Campos obrigatórios não preenchidos.</span>
    </v-tooltip>
    <Button
      v-else
      variant="primary"
      :disabled="disabled"
      @click="onConfirm"
    >
      <SpinnerIcon
        v-if="isLoading"
        class="spinner"
      />
      <span v-else>{{ buttonText }}</span>
    </Button>
  </div>
</template>

<script>

import { mapMutations } from 'vuex';
import SpinnerIcon from '@/components/icons/SpinnerIcon.vue';
import Button from '@/components/base/Button';

export default {
  name: 'ButtonBar',
  components: { SpinnerIcon, Button },
  props: {
    tooltip: {
      type: Boolean,
      default: true,
    },
    onClean: {
      type: Function,
      default: () => null,
    },
    onConfirm: {
      type: Function,
      default: () => null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      default: 'Confirmar',
    },
  },
  methods: {
    ...mapMutations({
      setRegionSelected: 'search/SET_REGION_SELECTED',
    }),
    handleOnClean () {
      this.setRegionSelected(null);
      this.onClean();
    },
  },
};
</script>

<style lang="scss" scoped>
.button-area {
  background-color: $colors-neutral-white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:16px;
  z-index: 50;
  width: 100%;

  .spinner {
    color: $colors-neutral-white;
    margin: auto;
    height: 1.25rem;
    width: 1.25rem;
    animation: spin 1s linear infinite;

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
