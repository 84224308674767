/**
 * Spacing
 */
const viewportHeight = window.innerHeight;
let xxmSize = null;

if (viewportHeight < 889) {
  xxmSize = 60;
} else {
  xxmSize = 25;
}

export default {
  quarck: '4px',
  nano: '8px',
  xs: '12px',
  small: '16px',
  medium: '24px',
  large: '32px',
  xl: '40px',
  xxl: '48px',
  xxm: `${xxmSize}px`,
};