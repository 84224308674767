<template>
  <div class="clients-wrapper w-screen">
    <LGPDModal />
    <div class="flex h-full justify-space-between">
      <!-- eslint-disable-next-line -->
      <ConsultaDadosSidebar :search-for="searchFor" @searchResponse="setSidebarResponse"/>
      <div
        v-if="resultList"
        class="client-search-results-wrapper w-5/6 pl-6"
      >
        <div class="results-wrapper">
          <PeopleTable
            :error-start="2"
            :error-end="headers.length"
            :result-list="resultList"
            :headers="headers"
            :is-loading="isLoading"
            @click="getDetail"
          />
        </div>
      </div>
    </div>
    <GoogleTagManager :track-event="gtmEvent" />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import base from '@/api/base';
import ConsultaDadosSidebar from '@/components/search/ConsultaDadosSidebar.vue';
import PeopleTable from '@/components/tables/PeopleTable.vue';
import LGPDModal from '@/components/LGPD/LGPDModal';
import GoogleTagManager from '../components/google/GoogleTagManager';

export default {
  components: {
    GoogleTagManager,
    LGPDModal,
    PeopleTable,
    ConsultaDadosSidebar,
  },
  props: ['searchFor'],
  data () {
    return {
      gtmEvent: null,
      selectedRow: [],
      headers: [
        {
          text: 'Visualizado',
          value: 'Seen',
          width: '60px',
          sortable: false,
        },
        {
          text: 'Documento',
          value: 'DocumentFmt',
          width: '138px',
        },
        {
          text: 'Nome',
          value: 'Name',
        },
        {
          text: 'Idade',
          value: 'Age',
          width: '92px',
        },
        {
          text: 'Mãe',
          value: 'MotherNameFmt',
        },
        {
          text: 'Localidade',
          value: 'Location',
        },
      ],
    };
  },
  computed: {
    ...mapState('personData',['resultList'],
      'person', [
        'isLoading',
        'detail',
      ]),
    ...mapGetters('personData', ['seen']),
  },
  beforeMount () {
    this.SET_DETAIL_LOADING(false);
    this.SET_ERROR(null);
  },
  mounted () {
    this.disableMap();
  },
  methods: {
    async setSidebarResponse (response) {
      if (response) {
        let resultFiltered;

        if (response.data && response.data.result) {
          resultFiltered = response.data.result.filter(item => item.Name);
        } else {
          resultFiltered = null;
        }

        await this.setResultList(resultFiltered);

      } else {
        await this.setResultList(null);
      }
    },
    sendGTMEvent () {
      if (this.detail) {
        this.gtmEvent = {
          event: 'view_person',
          id: this.detail.SequencialId,
        };
      }
    },
    async getDetail (item) {
      if (this.isLoading) return;

      this.selectedRow = item;

      if (this.detail && this.detail.SequencialId === item.SequentialId) {
        this.sendGTMEvent();

        return this.$router.push('/person-data/detail');
      }

      this.SET_DETAIL_LOADING(true);
      this.SET_DETAIL(null);
      this.SET_HAS_FAC(null);
      this.SET_SCORE(null);

      try {
        const response = await base.post(
          'person-data/getByItem',
          {
            payLoad: item,
          },
          { context: 'person-data' },
        );

        this.SET_DETAIL_LOADING(false);

        this.SET_DETAIL(response.data.response);
        this.sendGTMEvent();

        if (!this.seen) {
          this.setSeen([item.SequentialId]);
        } else if (!this.seen.includes(item.SequentialId)) {
          this.setSeen([
            ...this.seen,
            item.SequentialId,
          ]);
        }

        this.$router.push('/person-data/detail');
      } catch {
        this.SET_DETAIL_LOADING(false);
      }
    },
    ...mapMutations('person', [
      'SET_DETAIL_LOADING',
      'SET_DETAIL',
      'SET_HAS_FAC',
      'SET_SCORE',
      'SET_ERROR',
    ]),
    ...mapActions({
      setResultList: 'personData/setResultList',
      disableMap: 'core/disableMap',
      fetchConsultaDadosQuota: 'user/fetchConsultaDadosQuota',
      setSeen: 'personData/setSeen',
    }),
  },
};
</script>

<style lang="scss" scoped>
.clients-wrapper {
  position: absolute;
  height: calc(100vh - 75px);
  top: 75px;
  background-color: $header-bg-color;
  background-size: cover;
}

.client-search-results-wrapper {
  height: calc(100vh - 75px);
  top: 75px;
  right: 0;
  z-index: 4;
  overflow: auto;
}

.client-search-results {
  overflow-y: auto;
  background-color: white;
  height: calc(100vh - 75px);
}
.results-wrapper {
  @apply bg-white py-3 px-5;
}
h1 {
  font-weight: bolder;
  color: $bold-gray;
}
</style>
