import { css } from '@/core/theme';

const root = css({
  position: 'fixed',
  bottom: '$xxm',
  right: '$xl',
  zIndex: 10,
});

const button = css({
  boxShadow: '$small !important',
});

export const styles = {
  root,
  button,
};
