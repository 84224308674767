export default function filterByOfferType(
  { commit, dispatch, rootState },
  payload
) {
  try {
    const {
      route: { name },
    } = rootState;

    const {
      lastSearch: [params, attributesFilter],
    } = rootState[name];

    commit('FILTER_BY_OFFER_TYPE', payload);
    dispatch(
      'summary/fetchSummary',
      { attributesFilter, params },
      { root: true }
    );
  } catch (error) {
    console.error('Erro ao filtrar pelo tipo de oferta:', error);
  } 
}
