<script setup>

import { computed, inject, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router/composables';
import { VueTyper } from 'vue-typer';
import base from '@/api/base';
import { formatYear, formatCurrency } from '@/utils';
import { CITY_BOUNDARIES } from '@/common/constants';
import api from '@/api';

import {
  NATIONALITY,
  MARITAL_STATUS,
  EDUCATION_LEVEL,
  GENDER,
  CLASS_SOCIAL,
} from '@/common/constants';

import Typography from '@/components/base/Typography';
import Button from '@/components/base/Button';
import BaseCard from '@/components/cards/base-card.vue';

import SmallButton from '@/components/mobile/SmallButton';
import ArrowBackIcon from '@/components/icons/ArrowBackIcon.vue';
import PinSmallIcon from '@/components/icons/PinSmallIcon.vue';
import MagnifySmallIcon from '@/components/icons/MagnifySmallIcon.vue';
import LGPDModal from '@/components/LGPD/LGPDModal.vue';

import QuotaNotification from '@/views/mobile/QuotaNotification.vue';
import SpinnerIcon from '../../components/icons/SpinnerIcon.vue';

const vuexStore = inject('vuex-store');

const router = useRouter();
const route = useRoute();

const props = defineProps(['step']);

const searchLabelPerson = [
  'número do CPF',
  'número do CNPJ',
  'e-mail',
  'nome completo',
];

const searchLabelAddress = ['endereço'];

const autocompleteOptions = {
  componentRestrictions: {
    country: 'br',
  },
  strictBounds: true,
  bounds: CITY_BOUNDARIES['ALL_COVERED_CITIES'],
};
const phonesMin = 4;
const emailsMin = 4;
const addressesMin = 4;
const propertiesMin = 4;

const loading = ref(false);
const loadingMore = ref(false);
const disableBtn = ref(false);
const disableConditionResults = ref(true);
const list = ref([]);
const searchTerm = ref('');
const searchByAddressPayload = ref({});
const personData = ref({});
const isAddressSearch = ref(false);
const resultsContainer = ref(null);
const personDataContainer = ref(null);
const quotaNotificationDisplay = ref(false);
const phonesMax = ref(4);
const emailsMax = ref(4);
const addressesMax = ref(4);
const propertiesMax = ref(4);
const indicatorIds = ref([
  3,
  8,
  2,
  14,
  6,
  13,
]);
const indicatorName = ref([
  {
    name: 'Conta bancária premium',
    id: 3,
  },
  {
    name: 'Posse de carro de luxo',
    id: 8,
  },
  {
    name: 'Conta de investimento',
    id: 2,
  },
  {
    name: 'Itens de luxo',
    id: 14,
  },
  {
    name: 'Posse de imóvel de alto padrão',
    id: 6,
  },
  {
    name: 'Tomador de empréstimo',
    id: 13,
  },
]);

const filteredConsumptionProfile = computed(() => {
  const dataFiltered = personData?.value?.StatisticModels?.filter((indicator) => {
    if (indicatorIds.value.includes(indicator.Id)) {
      return indicator;
    }
  });

  return dataFiltered;
});

const searchLabel = computed(() => isAddressSearch.value ? searchLabelAddress : searchLabelPerson);
const arrowPosition = computed(() => {
  if (personData.value && Object.keys(personData.value).length > 0) {
    if (personData.value.CreditScore.D00 > 0) {
      const percent = (personData.value.CreditScore.D00 / 1000) * 100;
      const style = `left: calc(${percent}% - 10px)`;

      return style;
    }
  }

  return 'left: -5px';
});

let currentPage = 1;

const searchPayload = computed(() => {
  if (isAddressSearch.value) {
    return {
      ...searchByAddressPayload.value,
      PersonType: 0,
      size: 300,
      page: currentPage,
    };
  }

  return {
    'Query': searchTerm.value,
    size: 300,
    page: 1,
  };
});

const quota = computed(() => vuexStore.getters['user/consultaDadosQuota']);
const seen = computed(() => vuexStore.getters['personData/seen']);

function circlePosition (id) {
  if (personData?.value?.StatisticModels && Object.keys(personData?.value?.StatisticModels).length > 0) {
    const score = personData?.value?.StatisticModels?.find(indicator => indicator.Id === id).Score;

    if (score > 0) {
      const percent = (score / 1000) * 100;
      const style = `left: calc(${percent}% - 10px)`;

      return style;
    }
  }

  return 'left: -5px';
}

function findScore (id) {
  if (filteredConsumptionProfile?.value && Object.keys(filteredConsumptionProfile?.value).length > 0) {
    return filteredConsumptionProfile?.value?.find(indicator => indicator.Id === id).Score;
  }

  return '-';
}

function linkToMaps (property) {
  return `https://www.google.com/maps/place/${property?.Alias ? property?.Alias : property?.Address}`;
}

function scoreColor (score) {
  if (score <= 200) {
    return 'score-color-awful';
  } else if (score > 200 && score <= 400) {
    return 'score-color-low';
  } else if (score > 400 && score <= 600) {
    return 'score-color-average';
  } else if (score > 600 && score <= 800) {
    return 'score-color-good';
  } else if (score > 800) {
    return 'score-color-great';
  }
}

async function loadMoreResults () {
  if (!quota.value.available) {
    quotaNotificationDisplay.value = true;

    return;
  }

  if (loadingMore.value) {
    return;
  }

  try {
    loadingMore.value = true;

    currentPage += 1;

    const nextPayload = {
      ...searchPayload.value,
      page: currentPage,
      size: 300,
    };

    const response = await base.post(
      'person-data/search',
      nextPayload,
      {
        context: 'mobile-person-data',
      },
    );

    const newResults = response?.data?.result || [];

    if (newResults.length > 0) {
      list.value = [
        ...list.value,
        ...newResults,
      ];
       
    } else {
      disableBtn.value = false;      
    }
  } catch (error) {
    console.error('Erro ao carregar mais resultados:', error);
  } finally {
    loadingMore.value = false;
  }
}

async function fetchList () {
  if (parseInt(quota.value.available)) {
    loading.value = true;
  }

  try {
    loading.value = true;

    disableBtn.value = true;   

    currentPage = 1;

    const payload = {
      ...searchPayload.value,
      page: currentPage,
      size: 300,
    };

    if (payload.Query) {
      disableConditionResults.value = false;
    } else {
      disableConditionResults.value = true;
    }

    const response = await base.post('person-data/search', payload, {
      context: 'mobile-person-data',
    });

    if (response?.data?.result) {
      list.value = response?.data?.result;
    } else {
      list.value = [];
    }

    if (list.value !== undefined) {
      return router.push('/mobile/person-data/list');
    }
  } catch (error) {
    console.error('Erro ao buscar resultados:', error);
    list.value = [];
  } finally {
    loading.value = false;
  }
}

async function fetchPersonData (person) {
  if (parseInt(quota.value.available)) {
    loading.value = true;

    try {
      const result = await base.post(
        'person-data/getByItem',
        {
          payLoad: person,
        },
        { context: 'person-data' },
      );

      personData.value = result?.data?.response;
    } catch (error) {
      personData.value = undefined;
    }

    loading.value = false;

    if (!seen.value) {
      vuexStore.dispatch('personData/setSeen', [person?.SequentialId]);
    } else {
      if (!seen.value.includes(person.SequentialId)) {
        vuexStore.dispatch('personData/setSeen', [
          ...seen.value,
            person?.SequentialId,
        ]);
      }
    }

    if (personData.value !== undefined) {
      router.push('/mobile/person-data/detail');

      const mainContentDiv = window.document.querySelector('#main-content');

      setTimeout(() => {
        if (mainContentDiv.scrollTop > 0) {
          mainContentDiv.scrollTo({
            top: -1,
            behavior: 'smooth',
          });
        }
      }, 500);
    }

    return;
  }

  quotaNotificationDisplay.value = true;
}

function hasBeenSeen (id) {
  if (seen.value) {
    return seen.value.some(item => item === id);
  }

  return false;
}

function changeSearchType () {
  isAddressSearch.value = !isAddressSearch.value;
}

function onAutocompleteInputChange (event) {
  searchTerm.value = event.target.value;
}

function onAutoCompleteSelection (data) {
  const { address_components } = data;
  const payload = {};

  address_components.forEach(component => {
    component.types.forEach(type => {
      switch (type) {
      case 'route':
        payload.Street = component.long_name;
        break;
      case 'administrative_area_level_1':
        payload.State = component.short_name;
        break;
      case 'street_number':
        payload.AddressNumber = component.long_name;
        break;
      case 'administrative_area_level_2':
        payload.City = component.long_name;
        break;
      case 'postal_code':
        payload.ZipCode = component.long_name.replace(/\D/g, '');
        break;
      default:
        break;
      }
    });
  });

  searchByAddressPayload.value = payload;
}

function quotaNotificationAction () {
  router.push('/mobile/home');
}

if (route.query?.owner) {
  searchTerm.value = route.query.owner;
  fetchList();
}

if (route.query?.data) {
  api.person.decrypt('person', route.query.data).then(response => {
    searchTerm.value = response.decrypted;
    fetchList();
  });
}

setTimeout(() => {
  if (document.querySelector('.v-overlay--active')) {
    document.querySelector('.v-overlay--active').style['z-index'] = 1001;
    document.querySelector('.v-dialog__content.v-dialog__content--active').style['z-index'] = 1002;
    document.querySelector('.lgpd-modal').style['z-index'] = 1003;
  }
}, 500);

watch(route, async (_newPath, _oldPath) => {
  await vuexStore.dispatch('user/fetchConsultaDadosQuota');

  if (!parseInt(quota.value.available)) {
    quotaNotificationDisplay.value = true;
  }
});

vuexStore.dispatch('user/fetchConsultaDadosQuota');

</script>

<template>
  <div
    ref="personDataContainer"
    class="person-data"
  >
    <QuotaNotification
      :display="quotaNotificationDisplay"
      @quota-notification-click="() => quotaNotificationAction()"
    />
    <LGPDModal />
    <div class="header">
      <Typography
        class="my-4"
        color="brand-lighter"
        variant="header-large"
        tag="h1"
      >
        Consulta Dados
      </Typography>
    </div>
    <div class="flex flex-col flex-grow justify-center w-full">
      <template v-if="step === 'search'">
        <div class="flex flex-col px-8">
          <router-link
            class="mt-4"
            to="/mobile/home"
          >
            <SmallButton
              label="Voltar"
              :icon="ArrowBackIcon"
            />
          </router-link>
          <div class="flex flex-col justify-center flex-grow gap-8">
            <Typography
              color="brand-lighter"
              variant="title-large-secondary"
              tag="div"
            >
              Digite o&nbsp;
              <vue-typer
                :text="searchLabel"
                :pre-erase-delay="500"
                :pre-type-delay="1000"
                :erase-delay="2000"
                caret-animation="smooth"
              />
            </Typography>
            <input
              v-if="!isAddressSearch"
              v-model="searchTerm"
              type="text"
              class="border-b border-solid border-white text-lg text-white mt-8"
            >
            <gmap-autocomplete
              v-if="isAddressSearch"
              ref="addressAutocomplete"
              class="border-b border-solid border-white text-lg text-white mt-8"
              placeholder=""
              :options="autocompleteOptions"
              @keyup="onAutocompleteInputChange"
              @place_changed="(evt) => onAutoCompleteSelection(evt)"
            />

            <Button
              variant="primary"
              size="medium"
              :full-width="true"
              @click="fetchList()"
            >
              <SpinnerIcon
                v-if="loading"
                class="spinner"
              />
              <span v-else> Confirmar </span>
            </Button>

            <SmallButton
              :label="isAddressSearch ? 'Busca principal' : 'Buscar por endereço'"
              :icon="isAddressSearch ? MagnifySmallIcon : PinSmallIcon"
              class="my-4 justify-center"
              @small-button-click="() => changeSearchType()"
            />
          </div>
        </div>
      </template>

      <template v-else-if="step === 'list'">
        <div class="flex flex-col w-full">
          <div class="px-4">
            <SmallButton
              label="Voltar"
              :icon="ArrowBackIcon"
              class="my-4"
              @small-button-click="() => router.back()"
            />
          </div>
          <div class="px-4">
            <Typography
              class="my-4 text-center"
              color="brand-lighter"
              variant="header-xl"
              tag="h1"
            >
              {{ list ? 'Selecione' : '' }}
            </Typography>
          </div>
          <div class="p-8 bg-white rounded-t-lg flex-grow">
            <template v-if="list.length > 0 && !loading">
              <div
                v-for="(person, id) in list"
                :key="id"
                class="my-2 w-full flex flex-col gap-2"
                @click="fetchPersonData(person)"
              >
                <div class="flex flex-row justify-between">
                  <div class="flex flex-row gap-2">
                    <img
                      v-if="hasBeenSeen(person.SequentialId)"
                      src="@/assets/images/seen.svg"
                      data-test-id="seen"
                    >
                    <Typography
                      :color="hasBeenSeen(person.SequentialId) ? 'highlight-light' : 'brand-dark'"
                      variant="title-medium"
                      tag="div"
                    >
                      {{ person.Name }}
                    </Typography>
                  </div>
                  <Typography
                    :color="hasBeenSeen(person.SequentialId) ? 'highlight-light' : 'brand-dark'"
                    variant="title-medium"
                    tag="div"
                  >
                    {{ person.Age }}
                  </Typography>
                </div>
                <div class="flex flex-row justify-start gap-2">
                  <Typography
                    color="neutral-grey-4"
                    variant="title-small"
                    tag="div"
                  >
                    Documento:
                  </Typography>
                  <Typography
                    :color="hasBeenSeen(person.SequentialId) ? 'highlight-light' : 'brand-dark'"
                    variant="title-small"
                    tag="div"
                  >
                    {{ person.DocumentFmt }}
                  </Typography>
                </div>
                <div class="flex flex-row justify-start gap-2">
                  <Typography
                    color="neutral-grey-4"
                    variant="title-small"
                    tag="div"
                  >
                    Mãe:
                  </Typography>
                  <Typography
                    :color="hasBeenSeen(person.SequentialId) ? 'highlight-light' : 'brand-dark'"
                    variant="title-small"
                    tag="div"
                  >
                    {{ person.MotherNameFmt }}
                  </Typography>
                </div>
                <div class="flex flex-row justify-start gap-2">
                  <Typography
                    color="neutral-grey-4"
                    variant="title-small"
                    tag="div"
                  >
                    Localidade:
                  </Typography>
                  <Typography
                    :color="hasBeenSeen(person.SequentialId) ? 'highlight-light' : 'brand-dark'"
                    variant="title-small"
                    tag="div"
                  >
                    {{ person.Location }}
                  </Typography>
                </div>
                <hr>
              </div>
              <template v-if="disableConditionResults">
                <div>
                  <Button
                    v-if="disableBtn"
                    variant="primary"                    
                    class="mx-auto mt-5 p-6"
                    @click="loadMoreResults"
                  >
                    <SpinnerIcon
                      v-if="loadingMore"
                      class="spinner"
                    />
                    <span v-else>Carregar mais resultados</span>
                  </Button>
                  <p
                    v-else                    
                    class="text-center text-blue-900 font-semibold"
                  >
                    Sem mais resultados
                  </p>
                </div>
              </template>
            </template>
            <template v-else>
              <Typography
                v-if="loading"
                color="brand-dark"
                variant="button-small"
                tag="div"
                class="text-center mt-4"
              >
                Carregando...
              </Typography>
              <Typography
                v-else
                color="brand-dark"
                variant="button-small"
                tag="div"
                class="text-center mt-4"
              >
                Não encontramos resultados para sua busca.
                Verifique os dados inseridos ou tente novamente.
              </Typography>
            </template>
          </div>
        </div>
      </template>

      <template v-else>
        <div
          ref="resultsContainer"
          class="flex flex-col w-full"
        >
          <div class="px-4">
            <SmallButton
              :icon="ArrowBackIcon"
              label="Voltar"
              class="my-4"
              @small-button-click="() => router.back()"
            />
          </div>
          <div class="px-4">
            <Typography
              class="my-4 text-center"
              color="brand-lighter"
              variant="header-xl"
              tag="h1"
            >
              {{ personData.Name }}
            </Typography>
          </div>
          <div class="flex flex-col p-4 bg-white rounded-t-lg items-center flex-grow gap-4">
            <div class="w-full text-right">
              <Typography
                color="neutral-grey-5"
                variant="body-small"
                tag="div"
              >
                Atualização em: {{ formatYear(personData.Ranking) }}
              </Typography>
            </div>
            <div class="w-full flex flex-column px-5 py-4 border-data">
              <div class="flex flex-row gap-2 mb-4">
                <div class="flex flex-col items-start gap-2 nationality-width">
                  <Typography
                    color="brand-dark"
                    variant="body-small"
                    tag="span"
                  >
                    Nacionalidade
                  </Typography>
                  <Typography
                    color="brand-dark"
                    variant="body-table"
                    tag="span"
                  >
                    {{ NATIONALITY[personData?.Person?.Nationality] || '-' }}
                  </Typography>
                </div>
                <div class="flex flex-col items-start gap-2">
                  <Typography
                    color="brand-dark"
                    variant="body-small"
                    tag="span"
                  >
                    CPF
                  </Typography>
                  <Typography
                    color="brand-dark"
                    variant="body-table"
                    tag="span"
                  >
                    {{ personData.DocumentFormatted }}
                  </Typography>
                </div>
                <div class="flex flex-col items-start gap-2 age-width">
                  <Typography
                    color="brand-dark"
                    variant="body-small"
                    tag="span"
                  >
                    Idade
                  </Typography>
                  <Typography
                    color="brand-dark"
                    variant="body-table"
                    tag="span"
                  >
                    {{ personData.Age }}
                  </Typography>
                </div>
              </div>
              <div class="flex flex-row gap-2 justify-between mb-4">
                <div class="flex flex-col items-start p-2 border-personal birthday-width">
                  <Typography
                    color="neutral-grey-5"
                    variant="body-xs"
                    tag="span"
                  >
                    Nascimento
                  </Typography>
                  <Typography
                    color="brand-dark"
                    variant="body-xs"
                    tag="span"
                  >
                    {{ formatYear(personData.BirthDate) }}
                  </Typography>
                </div>
                <div class="flex flex-col items-start p-2 border-personal education-width">
                  <Typography
                    color="neutral-grey-5"
                    variant="body-xs"
                    tag="span"
                  >
                    Escolaridade
                  </Typography>
                  <Typography
                    color="brand-dark"
                    variant="body-xs"
                    tag="span"
                  >
                    {{ EDUCATION_LEVEL[personData?.Person?.EducationLevel] || '-' }}
                  </Typography>
                </div>
                <div class="flex flex-col items-start p-2 border-personal marital-width">
                  <Typography
                    color="neutral-grey-5"
                    variant="body-xs"
                    tag="span"
                  >
                    Estado Civil
                  </Typography>
                  <Typography
                    color="brand-dark"
                    variant="body-xs"
                    tag="span"
                  >
                    {{ MARITAL_STATUS[personData?.Person?.MaritalStatus] || '-' }}
                  </Typography>
                </div>
              </div>
              <div class="flex flex-row gap-6">
                <div class="flex flex-col items-start gender-width">
                  <Typography
                    color="neutral-grey-5"
                    variant="body-xs"
                    tag="span"
                  >
                    Gênero
                  </Typography>
                  <Typography
                    color="brand-dark"
                    variant="body-xs"
                    tag="span"
                  >
                    {{ GENDER[personData.Gender] }}
                  </Typography>
                </div>
                <div class="flex flex-col items-start dependents-width">
                  <Typography
                    color="neutral-grey-5"
                    variant="body-xs"
                    tag="span"
                  >
                    N° Dependentes:
                  </Typography>
                  <Typography
                    color="brand-dark"
                    variant="body-xs"
                    tag="span"
                  >
                    {{ personData?.Person?.Dependents || '0' }}
                  </Typography>
                </div>
              </div>
            </div>
            <div class="w-full flex flex-column px-5 py-4 border-data income-data">
              <div class="flex flex-row gap-2 mb-4">
                <Typography
                  color="neutral-white"
                  variant="title-large"
                  tag="span"
                >
                  Renda e Classe Social
                </Typography>
              </div>
              <div class="flex align-center justify-center mb-4">
                <div class="income-separator" />
              </div>
              <div class="flex flex-row gap-2 mb-4">
                <div class="flex flex-col items-center justify-center gap-3">
                  <Typography
                    color="neutral-white"
                    variant="title-large"
                    tag="span"
                  >
                    Renda Presumida
                  </Typography>
                  <Typography
                    color="feedback-success"
                    variant="body-table"
                    tag="span"
                  >
                    {{ formatCurrency(personData?.Income?.Presumed) || '-' }}
                  </Typography>
                </div>
              </div>
              <div class="flex flex-row align-items justify-center gap-4 mb-4">
                <div class="flex flex-row align-center p-2 social-class-data social-class-width">
                  <Typography
                    color="neutral-white"
                    variant="body-xs"
                    tag="span"
                  >
                    Classe social pessoal
                  </Typography>
                  <Typography
                    color="neutral-white"
                    variant="body-table"
                    tag="span"
                  >
                    {{ CLASS_SOCIAL[personData?.Income?.PersonalClass] || '-' }}
                  </Typography>
                </div>
                <div class="flex flex-row align-center p-2 social-class-data social-class-width">
                  <Typography
                    color="neutral-white"
                    variant="body-xs"
                    tag="span"
                  >
                    Classe social familiar
                  </Typography>
                  <Typography
                    color="neutral-white"
                    variant="body-table"
                    tag="span"
                  >
                    {{ CLASS_SOCIAL[personData?.Income?.FamilyClass] || '-' }}
                  </Typography>
                </div>
              </div>
              <div class="flex flex-row align-items justify-evenly gap-2 mb-4">
                <div class="flex flex-col align-items justify-center gap-2">
                  <Typography
                    color="neutral-white"
                    variant="body-xs"
                    tag="span"
                  >
                    Renda<br>Aposentadoria
                  </Typography>
                  <Typography
                    color="neutral-white"
                    variant="body-table"
                    tag="span"
                  >
                    {{ formatCurrency(personData?.Income?.Retired) || '-' }}
                  </Typography>
                </div>
                <div class="flex flex-col align-items justify-center gap-2">
                  <Typography
                    color="neutral-white"
                    variant="body-xs"
                    tag="span"
                  >
                    Renda<br>Individual
                  </Typography>
                  <Typography
                    color="neutral-white"
                    variant="body-table"
                    tag="span"
                  >
                    {{ formatCurrency(personData?.Income?.Personal) || '-' }}
                  </Typography>
                </div>
                <div class="flex flex-col align-items justify-center gap-2">
                  <Typography
                    color="neutral-white"
                    variant="body-xs"
                    tag="span"
                  >
                    Renda<br>Familiar
                  </Typography>
                  <Typography
                    color="neutral-white"
                    variant="body-table"
                    tag="span"
                  >
                    {{ formatCurrency(personData?.Income?.Family) || '-' }}
                  </Typography>
                </div>
                <div class="flex flex-col align-items justify-center gap-2">
                  <Typography
                    color="neutral-white"
                    variant="body-xs"
                    tag="span"
                  >
                    Renda<br>Empresarial
                  </Typography>
                  <Typography
                    color="neutral-white"
                    variant="body-table"
                    tag="span"
                  >
                    {{ formatCurrency(personData?.Income?.Partner) || '-' }}
                  </Typography>
                </div>
              </div>
            </div>
            <div class="w-full flex flex-column px-5 py-4 border-data score-data">
              <div class="flex flex-row">
                <div class="flex flex-column gap-4 mb-4">
                  <Typography
                    color="brand-dark"
                    variant="title-large"
                    tag="span"
                  >
                    Score
                  </Typography>
                  <div class="flex flex-row gap-4">
                    <div class="flex flex-col items-start gap-2 score-width">
                      <Typography
                        color="brand-dark"
                        variant="body-xs"
                        tag="span"
                      >
                        Últimos 60 dias
                      </Typography>
                      <Typography
                        color="brand-dark"
                        variant="placeholder"
                        tag="span"
                      >
                        {{ personData?.CreditScore?.D60 > -1 ? personData?.CreditScore?.D60 : '-' }}
                      </Typography>
                    </div>
                    <div class="flex flex-col items-start gap-2 score-width">
                      <Typography
                        color="brand-dark"
                        variant="body-xs"
                        tag="span"
                      >
                        Últimos 30 dias
                      </Typography>
                      <Typography
                        color="brand-dark"
                        variant="placeholder"
                        tag="span"
                      >
                        {{ personData?.CreditScore?.D30 > -1 ? personData?.CreditScore?.D30 : '-' }}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div class="flex align-center justify-center actual-score-data">
                  <Typography
                    variant="header-xxl"
                    tag="span"
                    :class="scoreColor(personData?.CreditScore?.D00)"
                  >
                    {{ personData?.CreditScore?.D00 > -1 ? personData?.CreditScore?.D00 : '-' }}
                  </Typography>
                </div>
              </div>
              <div class="w-full flex flex-column">
                <span
                  class="relative arrow-score"
                  :style="arrowPosition"
                >
                  <img src="@/assets/images/icon-arrow-solid.svg">
                </span>
                <div class="score-meter" />
                <div class="flex flex-row align-start justify-between">
                  <Typography
                    color="brand-dark"
                    variant="caption"
                    tag="span"
                  >
                    0
                  </Typography>
                  <Typography
                    color="brand-dark"
                    variant="caption"
                    tag="span"
                  >
                    100
                  </Typography>
                </div>
              </div>
            </div>

            <base-card
              v-if="filteredConsumptionProfile?.length > 0"
              bg-color="#E3E8F5"
              class="baseCard"
            >
              <Typography
                color="brand-dark"
                variant="title-large"
                tag="span"
              >
                Perfil de consumo
              </Typography>
              <div class="flex flex-column gap-3">
                <div
                  v-for="indicator in indicatorName"
                  :key="indicator.id"
                  class="flex flex-column gap-1 w-full"
                >
                  <div class="flex flex-row align-baseline justify-between">
                    <Typography
                      color="highlight-dark"
                      variant="body-medium"
                      tag="span"
                    >
                      {{ indicator.name }}
                    </Typography>
                    <Typography
                      color="highlight-dark"
                      variant="body-xs"
                      tag="span"
                    >
                      Score {{ findScore(indicator.id) }}<Typography
                        color="neutral-grey-5"
                        variant="body-xs"
                        tag="span"
                      >
                        /1000
                      </Typography>
                    </Typography>
                  </div>
                  <div class="flex flex-column align-start">
                    <span
                      class="indicator"
                      :style="circlePosition(indicator.id)"
                    >
                      <img src="@/assets/images/consumption-indicator.svg">
                    </span>
                    <div class="consumption-profile-meter" />
                  </div>
                </div>
                <Typography
                  color="neutral-grey-4"
                  variant="label-small"
                  tag="span"
                >
                  Consideramos pontuação acima de 800 como o ideal para os perfis de consumo.
                </Typography>
              </div>
            </base-card>

            <div class="w-full flex flex-row">
              <hr class="border-t border-gray-500 border-solid w-full">
            </div>

            <div class="w-full flex flex-column">
              <Typography
                color="brand-dark"
                variant="title-large"
                tag="span"
                class="mb-4"
              >
                Dados
              </Typography>
              <div class="w-full flex flex-column px-5 py-4 border-data">
                <Typography
                  color="neutral-grey-5"
                  variant="body-medium"
                  tag="span"
                >
                  Telefones
                </Typography>

                <div
                  v-for="(phone, index) in personData?.Phones"
                  v-if="index < phonesMax"
                  :key="phone.Number"
                  class="w-full flex flex-column"
                >
                  <hr class="border-t border-solid w-full my-2">
                  <div class="flex flex-row align-center">
                    <div class="flex flex-column gap-2">
                      <Typography
                        color="brand-dark"
                        variant="body-table"
                        tag="span"
                      >
                        {{ phone.FormattedNumber }}
                      </Typography>
                      <Typography
                        color="brand-dark"
                        variant="body-xs"
                        tag="span"
                      >
                        Atualizado {{ formatYear(phone.CreateDate) }}
                      </Typography>
                    </div>
                    <div
                      v-if="phone.IsProcon"
                      class="flex align-center pill"
                    >
                      <v-icon
                        size="medium"
                        color="#E35050"
                      >
                        mdi-alert-circle
                      </v-icon>
                      <Typography
                        color="feedback-error"
                        variant="label-small"
                        tag="span"
                      >
                        Procon
                      </Typography>
                    </div>
                    <v-btn
                      v-if="phone.IsWhatsapp"
                      class="button-whatsapp"
                      icon
                      width="30"
                      height="30"
                      color="#24C196"
                      :href="`https://api.whatsapp.com/send?phone=55${phone.AreaCode}${phone.Number}`"
                      target="_blank"
                    >
                      <v-icon
                        color="#FFFFFF"
                        size="16"
                      >
                        mdi-whatsapp
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="!phone.IsWhatsapp"
                      class="button-invisible"
                      height="30"
                    />
                  </div>
                </div>

                <div
                  v-if="personData?.Phones"
                  class="flex align-center justify-center w-full mt-2"
                >
                  <v-btn
                    v-if="personData?.Phones?.length > phonesMax"
                    depressed
                    outlined
                    rounded
                    color="#3566C9"
                    @click="() => phonesMax += 4"
                  >
                    <span class="button-text">
                      Ver mais
                    </span>
                  </v-btn>
                  <v-btn
                    v-if="personData?.Phones?.length > phonesMin && personData?.Phones?.length <= phonesMax"
                    depressed
                    outlined
                    rounded
                    color="#3566C9"
                    @click="() => phonesMax = 4"
                  >
                    <span class="button-text">Ver menos</span>
                  </v-btn>
                </div>
                <div
                  v-if="!personData?.Phones"
                  class="flex flex-column align-center justify-center"
                >
                  <hr class="border-t border-solid w-full my-2">
                  <Typography
                    color="brand-dark"
                    variant="body-xs"
                    tag="span"
                  >
                    Não foram encontrados dados sobre telefones
                  </Typography>
                </div>
              </div>
            </div>
            <div class="w-full flex flex-column px-5 py-4 border-data">
              <Typography
                color="neutral-grey-5"
                variant="body-medium"
                tag="span"
              >
                Emails
              </Typography>
              <div
                v-for="(email, index) in personData?.Emails"
                v-if="index < emailsMax"
                :key="email.Email"
                class="w-full flex flex-column"
              >
                <hr class="border-t border-solid w-full my-2">
                <div class="flex flex-row align-center">
                  <div class="flex flex-column gap-2 w-2/3 overflow-email">
                    <Typography
                      color="brand-dark"
                      variant="body-table"
                      tag="span"
                    >
                      {{ email.Email }}
                    </Typography>
                    <Typography
                      color="brand-dark"
                      variant="body-xs"
                      tag="span"
                    >
                      Atualizado {{ formatYear(email.CreateDate) }}
                    </Typography>
                  </div>
                  <div
                    v-if="email.IsValidated"
                    class="validated-pill w-1/3"
                  >
                    <Typography
                      color="neutral-white"
                      variant="label-small"
                      tag="span"
                    >
                      Validado
                    </Typography>
                  </div>
                </div>
              </div>
              <div
                v-if="personData?.Emails"
                class="flex align-center justify-center w-full mt-2"
              >
                <v-btn
                  v-if="personData?.Emails?.length > emailsMax"
                  depressed
                  outlined
                  rounded
                  color="#3566C9"
                  @click="() => emailsMax += 4"
                >
                  <span class="button-text">
                    Ver mais
                  </span>
                </v-btn>
                <v-btn
                  v-if="personData?.Emails?.length > emailsMin && personData?.Emails?.length <= emailsMax"
                  depressed
                  outlined
                  rounded
                  color="#3566C9"
                  @click="() => emailsMax = 4"
                >
                  <span class="button-text">Ver menos</span>
                </v-btn>
              </div>
              <div
                v-if="!personData?.Emails"
                class="flex flex-column align-center justify-center"
              >
                <hr class="border-t border-solid w-full my-2">
                <Typography
                  color="brand-dark"
                  variant="body-xs"
                  tag="span"
                >
                  Não foram encontrados dados sobre e-mails
                </Typography>
              </div>
            </div>

            <div class="w-full flex flex-column px-5 py-4 border-data">
              <Typography
                color="neutral-grey-5"
                variant="body-medium"
                tag="span"
              >
                Proprietário
              </Typography>
              <div
                v-for="(property, index) in personData.Properties"
                v-if="index < propertiesMax"
                :key="property.Address"
                class="w-full flex flex-column"
              >
                <hr class="border-t border-solid w-full my-2">
                <div class="flex flex-row align-center justify-between gap-1">
                  <Typography
                    color="brand-dark"
                    variant="body-table"
                    tag="span"
                  >
                    {{ property.Address }}
                  </Typography>
                  <v-btn
                    v-if="property"
                    class="map-button"
                    depressed
                    outlined
                    rounded
                    color="#6B8CEF"
                    :href="linkToMaps(property)"
                    target="_blank"
                  >
                    <div class="flex gap-2">
                      <img
                        width="10px"
                        src="@/assets/images/map-pin-default.svg"
                      >
                      <Typography
                        color="highlight-light"
                        variant="button-medium"
                        tag="span"
                        class="text-no-caps"
                      >
                        Mapa
                      </Typography>
                    </div>
                  </v-btn>
                </div>
              </div>
              <div
                v-if="personData?.Properties"
                class="flex align-center justify-center w-full mt-2"
              >
                <v-btn
                  v-if="personData?.Properties?.length > propertiesMax"
                  depressed
                  outlined
                  rounded
                  color="#3566C9"
                  @click="() => propertiesMax += 4"
                >
                  <span class="button-text">
                    Ver mais
                  </span>
                </v-btn>
                <v-btn
                  v-if="personData?.Properties?.length > propertiesMin && personData?.Properties?.length <= propertiesMax"
                  depressed
                  outlined
                  rounded
                  color="#3566C9"
                  @click="() => propertiesMax = 4"
                >
                  <span class="button-text">Ver menos</span>
                </v-btn>
              </div>
              <div
                v-if="!personData?.Properties.length"
                class="flex flex-column align-center justify-center"
              >
                <hr class="border-t border-solid w-full my-2">
                <Typography
                  color="brand-dark"
                  variant="body-xs"
                  tag="span"
                >
                  Não foram encontrados dados
                </Typography>
              </div>
            </div>

            <div class="w-full flex flex-column px-5 py-4 border-data">
              <Typography
                color="neutral-grey-5"
                variant="body-medium"
                tag="span"
              >
                Correspondência
              </Typography>
              <div
                v-for="(address, index) in personData?.Addresses"
                v-if="index < addressesMax"
                :key="`${address.ShortAlias}${index}`"
                class="w-full flex flex-column"
              >
                <hr class="border-t border-solid w-full my-2">
                <div class="flex flex-row align-center justify-between gap-1">
                  <Typography
                    color="brand-dark"
                    variant="body-table"
                    tag="span"
                  >
                    {{ address.ShortAlias }}
                  </Typography>
                  <v-btn
                    v-if="address"
                    class="map-button"
                    depressed
                    outlined
                    rounded
                    color="#6B8CEF"
                    :href="linkToMaps(address)"
                    target="_blank"
                  >
                    <div class="flex gap-2">
                      <img
                        width="10px"
                        src="@/assets/images/map-pin-default.svg"
                      >
                      <Typography
                        color="highlight-light"
                        variant="button-medium"
                        tag="span"
                        class="text-no-caps"
                      >
                        Mapa
                      </Typography>
                    </div>
                  </v-btn>
                </div>
              </div>
              <div
                v-if="personData?.Addresses"
                class="flex align-center justify-center w-full mt-2"
              >
                <v-btn
                  v-if="personData?.Addresses?.length > addressesMax"
                  depressed
                  outlined
                  rounded
                  color="#3566C9"
                  @click="() => addressesMax += 4"
                >
                  <span class="button-text">
                    Ver mais
                  </span>
                </v-btn>
                <v-btn
                  v-if="personData?.Addresses?.length > addressesMin && personData?.Addresses?.length <= addressesMax"
                  depressed
                  outlined
                  rounded
                  color="#3566C9"
                  @click="() => addressesMax = 4"
                >
                  <span class="button-text">Ver menos</span>
                </v-btn>
              </div>
              <div
                v-if="!personData?.Addresses"
                class="flex flex-column align-center justify-center"
              >
                <hr class="border-t border-solid w-full my-2">
                <Typography
                  color="brand-dark"
                  variant="body-xs"
                  tag="span"
                >
                  Não foram encontrados dados
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.baseCard {
  display: flex;
  padding: 20px 16px 16px 16px !important;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px 26px;
  flex-wrap: wrap;
  border-radius: 20px !important;
}

.person-data {
  position: absolute;
  top: 0;
  left: 0;

  width: clamp(100%, 100%, 100vw);
  min-height: 100dvh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #1B2644;

  z-index: 999;

  .vue-typer .char {
    color: #C7D0EA !important;

    &.selected {
      background-color: #768ac6 !important;
      color: #C7D0EA !important;
    }
  }

  .vue-typer .caret {
    background-color: #C7D0EA !important;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  width: 100%;
}

.range {
  position: relative;
  height: 20px;
  margin-top: 20px;

  p {
    margin: 0;
    padding: 0;
  }

  &__container {
    position: relative;
    margin: 0 5px;
    height: 100%;

    img {
      display: block;
      margin-top: 8px;
    }

    span {
      position: absolute;
      top: -16px;
      width: 14px;
    }
  }
}

.border-data {
  border-radius: 20px;
  border: 1px solid var(--colors-neutral-grey-2);
}

.income-data {
  background: var(--colors-brand-dark);
}

.score-data {
  background: var(--colors-neutral-grey-2);
}

.social-class-data {
  border-radius: 10px;
  border: 1px solid var(--colors-neutral-grey-2);
}

.actual-score-data {
  border-radius: 10px;
  border: 1px solid var(--colors-brand-default);
  width: auto;
  max-width: 84px;
  height: 54px;
}

.income-separator {
  width: 209px;
  border: 0;
  border-top: 1px solid var(--colors-neutral-grey-4);
}

.pill {
  border-radius: 9999px;
  gap: 10px;
}

.validated-pill {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 20px;
  padding: 5px 8px;
  border-radius: 560px;
  background: var(--colors-brand-dark);
  gap: 10px;

}

.button-whatsapp {
  background: var(--colors-feedback-success);
}

.age-width {
  max-width: 40px;
}

.nationality-width {
  max-width: 105px;
}

.birthday-width {
  max-width: 75px;
}

.education-width,
.marital-width {
  max-width: 115px;
}

.gender-width {
  min-width: 65px;
  max-width: fit-content;
}

.dependents-width {
  max-width: 85px;
}

.social-class-width {
  max-width: 105px;
}

.score-width {
  max-width: 85px;
}

.border-personal {
  border-radius: 10px;
  border: 1px solid var(--colors-neutral-grey-2);
}

.overflow-email {
  overflow-wrap: break-word;
}

.map-button {
  width: 85px !important;
  height: 32px !important;
}

.text-no-caps {
  text-transform: none !important;
}

.button-text {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-transform: none;
}

.score-color-awful {
  color: #E35050 !important;
}

.score-color-low {
  color: #E3A350 !important;
}

.score-color-average {
  color: #E3D401 !important;
}

.score-color-good {
  color: #50E38B !important;
}

.score-color-great {
  color: #50E355 !important;
}

.score-meter {
  width: 100%;
  height: 20px;
  border-radius: 20px;
  background-image: linear-gradient(90deg,
      hsl(0deg 100% 36%) 0%,
      hsl(29deg 100% 46%) 33%,
      hsl(49deg 100% 41%) 67%,
      hsl(104deg 100% 45%) 100%);
}

.consumption-profile-meter {
  width: 100%;
  position: relative;
  top: -14px;
  height: 10px;
  border-radius: 20px;
  border: 2px solid var(--colors-neutral-grey-4);
  background-image: linear-gradient(90deg,
      hsl(0deg 100% 36%) 0%,
      hsl(29deg 100% 46%) 33%,
      hsl(49deg 100% 41%) 67%,
      hsl(104deg 100% 45%) 100%);
}

.indicator {
  position: relative;
  z-index: 1000;
}

.button-invisible {
  min-width: 30px !important;
  background-color: var(--colors-neutral-white) !important;
  box-shadow: none;
  cursor: none;
  pointer-events: none;
  padding: 0 !important;
}

.arrow-score {
  width: 15px;
}

.spinner {
  color: $colors-neutral-white;
  margin: auto;
  height: 1.25rem;
  width: 1.25rem;
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
</style>
